<template>
  <Error404 />
</template>

<script>
export default {
  name: 'Error404Page',
  components: {
    Error404: () => import('components/common/Error404'),
  },
}
</script>
